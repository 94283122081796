class AgentManager {
  constructor() {
    this.agents = {
      AnchorMe: this.anchorMeAgent.bind(this)
    };
    this.linkCopiedElement = null;
  }

  init() {
    this.linkCopiedElement = document.querySelector('.link_copied');
    console.log('Initializing AgentManager');
    const elements = document.querySelectorAll('[data-agent]');
    //console.log(`Found ${elements.length} elements with data-agent attribute`);
    elements.forEach(element => {
      const agent = element.getAttribute('data-agent');
      if (this.agents[agent]) {
        this.agents[agent](element);
      }
    });
  }

  anchorMeAgent(element) {
    const link = element.querySelector('.-heading_link');
    if (link) {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const url = link.href;
        navigator.clipboard.writeText(url).then(() => {
          //console.log('URL copied to clipboard:', url);
          this.showCopiedNotification();
        }).catch(err => {
          console.error('Failed to copy URL: ', err);
        });

        // Smooth scroll to the target with offset
        const targetId = link.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const offset = 20; // 20px offset
          const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      });
    }
  }

  showCopiedNotification() {
    if (this.linkCopiedElement) {
      this.linkCopiedElement.style.display = 'block';
      setTimeout(() => {
        this.linkCopiedElement.style.display = 'none';
      }, 3000);
    }
  }
}

export default AgentManager;