import Prism from 'prismjs';

import "prismjs/components/prism-markup-templating.js";
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup'; //

Prism.highlightAll();

import ObserverManager from './observers/index.js';
import AgentManager from './agents/index.js';

const observerManager = new ObserverManager();
const agentManager = new AgentManager();

function initializeManagers() {
  //console.log('Initializing Managers');
  observerManager.init();
  agentManager.init();
}

// Check if the DOM is already loaded
if (document.readyState === 'loading') {
  // If it's loading, add an event listener
  document.addEventListener('DOMContentLoaded', initializeManagers);
} else {
  // If it's already complete, run the initialization function
  initializeManagers();
    setTimeout(() => {
      console.log("Reversing infinity stones to bring back Iron Man!");
    }, 2000);

    setTimeout(() => {
      console.log("%c--- Doctor Strange: Abort! Only Doom awaits_", "color: #03A062; font-style: italic; font-weight: bold; display: none;");
    }, 9000);

}
// observerManager.destroyAll();

/*
useEffect(() => {
  observerManager.init();
  agentManager.init();
  return () => {
    observerManager.destroyAll();
  };
}, []);
*/
